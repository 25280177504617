import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { connect } from "react-redux";
import { pointsUpdate } from "../../redux/actions";
import WindowFocusHandler from "../WindowFocusHandler";
import Timer from "../Timer";
import { toast } from "react-toastify";
const ReadEarn = (props) => {
	const { t } = useTranslation();
	const activityData = props.activityData;
	const setLoading = props.setLoading;
	let { id } = useParams();
	const [activityStatus, setActivityStatus] = useState({ status: false, activityEventId: "" });
	const [readData, setReadData] = useState(null);
	const [activityUpdateApiCall, setActivityUpdateApiCall] = useState(false);
	const [pauseTimer, setPauseTimer] = useState(true);
	useEffect(() => {
		setLoading(true);
		let data = {
			reference: id,
			state: "started",
		};
		props
			.pointsUpdate(data, "post")
			.then((responseData) => {
				if (responseData["data"]) {
					if (activityData["channel"]) {
						if (activityData["channel"] === "html") {
							loadHtml(activityData["data"]);
						} else {
							setReadData(activityData["data"]);
							setLoading(false);
						}
					}
					setActivityStatus(Object.assign({}, activityStatus, { status: true, activityEventId: responseData.data.id }));
				} else {
					setActivityStatus(Object.assign({}, activityStatus, { status: false, activityEventId: "none" }));
					setLoading(false);
				}
			})
			.catch((err) => {
				console.log("Response Error:", JSON.stringify(err));
				setActivityStatus(Object.assign({}, activityStatus, { status: false, activityEventId: "none" }));
				setLoading(false);
			});
		// eslint-disable-next-line
	}, []);
	const loadHtml = (url) => {
		fetch(url)
			.then((response) => response.text())
			.then((data) => {
				setReadData(data);
				setLoading(false);
				setPauseTimer(false);
			})
			.catch((ex) => {
				setReadData(url);
				setLoading(false);
				console.log("error", ex);
			});
	};
	const iframeLoader = (e) => {
		setLoading(false);
		setPauseTimer(false);
	};
	const tabOnFocus = () => {
		if (!activityUpdateApiCall) {
			setPauseTimer(false);
		}
	};
	const tabOnBlur = () => {
		if (activityData["channel"] === "html") {
			setPauseTimer(true);
		}
	};
	const timeCompleted = () => { 
		setPauseTimer(true);
		setActivityUpdateApiCall(true);
		let data = {
			id: activityStatus.activityEventId,
			state: "completed",
		};
		if (activityStatus.activityEventId !== "") {
			props
				.pointsUpdate(data, "put", "read")
				.then((responseData) => {
					if (responseData["data"]) {
						toast.success(`${t("Congratulations")}! ${t("You have earned")} ${activityData.points} ${t("Points")}.`);
					} else {
						toast.error(`${t("Sorry")}! ${t("You've earned the maximum points possible for this action")}.`);
					}
				})
				.catch((err) => {
					if (activityStatus.activityEventId === "none") {
						toast.error(`${t("Sorry")}! ${t("You've earned the maximum points possible for this action")}.`);
					} else {
						toast.error(t("Something went wrong"));
					}
					console.log("Response Error:", JSON.stringify(err));
				});
		} else {
			toast.error(`${t("Sorry")}! ${t("You've earned the maximum points possible for this action")}.`);
		}
	};
	return (
		<>
		<div className="user-pages">
			<div className="page-section">
				<div className="portal-point-list-container">
					<Container fluid>
						<Row className="bg-light py-2">
							<Col md={10}>
								<Link id="headerParentLink" className="text-link-plain" to="/earnpoints">
									<Trans>Earn Points</Trans>
								</Link>{" "}
								<b>{activityData["title"] ? activityData.title : ""}</b> 
							</Col>
							<Col className="float-right" md={2}>
								{readData && !activityUpdateApiCall ? <Timer pauseTimer={pauseTimer} initialMinute={parseInt(activityData["minimumActivityDuration"] / 60)} initialSeconds={activityData["minimumActivityDuration"] % 60} timeCompleted={timeCompleted} /> : ""}
							</Col>
						</Row>
						<Row>
							<div className="col-sm-6 py-3">
								<img className="img-fluid" src="/public/assets/images/earn-points/catch-up-nutrena-content/image1.png" alt="image" />
							</div>
							<div className="col-sm-6 py-5">
								<div>
									<h3 className="pb-3">
										<Trans>Complete this</Trans>
										{" "}
										{activityData["title"] ? activityData.title : ""} <Trans>and get</Trans> {activityData["points"] ? activityData.points : 0} <Trans>Points</Trans>
									</h3>
									<p className="description">{activityData["description"] ? activityData.description : ""}</p>
								</div>
							</div>
						</Row>
						
						
					</Container>
					<Container fluid>
						{activityStatus.activityEventId === "" ? (
							<div className="survey-message-div">
								<h1 className="alignCenter">
									<Trans>Loading</Trans>..........
								</h1>
							</div>
						) : (
							<>
								{activityStatus.activityEventId === "none" ? (
									<div className="survey-message-div">
										<h3 className="alignCenter">
											<Trans>Sorry</Trans>!
										</h3>
										<h3 className="alignCenter">
											<Trans>You have already earned the maximum number of points possible for this action</Trans>.
										</h3>
									</div>
								) : activityData["channel"] !== "html" ? (
									<embed width="100%" id="readIFrame" onLoad={iframeLoader} height="800" src={readData} type="application/pdf" />
								) : (
									<Row col-md={1}>
										<Col id="read-div-content" dangerouslySetInnerHTML={{ __html: readData }} />
									</Row>
								)}
								<WindowFocusHandler tabOnFocus={tabOnFocus} tabOnBlur={tabOnBlur} />
							</>
						)}
					</Container>
				</div>
			</div>
		</div>
		</>
	);
};
export default React.memo(connect(null, { pointsUpdate })(IsLoadingHOC(ReadEarn, "Loading....")));
