import React, { useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import {faqCategory} from '../data/indexNew'

const FrequentlyAskedQuestions = () => {	
	const [data, setData] = useState(faqCategory||[]);
	return (
		<div>
		<div className="page faq-page">
			<div className="page-section">
				<div className="container">
					<div className="">
						<Row className="justify-content-md-center section-content faq" id="faq-content">
							<Col md={12}>
								<h1 className="text-center pb-3">Frequently Asked Questions</h1>
							</Col>
							<Col md={12}>
								<Row>
									{
										data && data.map((item,i) => (
											<Col md={6} className="my-3" key={i}>
												<Card>
													<img className="card-img img-fluid" src={item.image} alt="..." />
													<a href={`/faq/${item.slug}`} className="heading-link">
														<div className="card-img-overlay text-white d-flex align-items-center text-center">
															<div className="text-center w-100 d-block">
																<h3 className="text-center w-100">{item.name}</h3>
															</div>
														</div>
													</a>
												</Card>
											</Col>
										))
									}
								</Row>
							</Col>
							
						</Row>
					</div>
				</div>
			</div>
		</div>
		<div className="w-100">
			<div className="faq-wrapper">
				<div className="faq-bot-banner">
					<div className="container">
						<div className="row">
							<div className="col-sm-5 d-flex align-items-center">
								<div className="">
									<h2 className="text-left">Still have questions?<br />
									We're here to help.</h2>
									<a href="https://www.nutrenaworld.com/plaid-perks-inquiry" className="btn btn-primary" target="_blank">Contact Us</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</div>
	);
};

export default FrequentlyAskedQuestions;
