import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import IsLoadingHOC from "./../IsLoadingHOC";
import { receiptListAction } from "./../../redux/actions";
import TableReport from "./../TableReport";
const PreviousReceiptList = (props) => {
	const { t } = useTranslation();
	const [tableList, setTableList] = useState([]);
	const columns = [
		{ dataField: "date", text: t("Date Submitted") },
		{ dataField: "status", text: t("Receipt status") },
	];
	useEffect(() => {
		props.setLoading(true);
		receiptListAction()
			.then((responseData) => {
				if (responseData.httpCode === 200 && responseData.data.content) {
					const respData = responseData.data.content.map((v) => {
						let itemDate = new Date(v.date).toLocaleDateString();
						let d = JSON.parse(JSON.stringify(v));
						d.date = itemDate;
						return d;
					});
					respData.sort((a, b) => {
						return new Date(b.date) - new Date(a.date);
					});
					setTableList(respData);
				} else {
					setTableList([]);
					console.log("responseData", responseData);
				}
				props.setLoading(false);
			})
			.catch((err) => {
				props.setLoading(false);
				setTableList([]);
				console.log("Response Error:", JSON.stringify(err));
			});
		// eslint-disable-next-line
	}, [props.reloadCounter]);
	if (tableList.length > 0) {
		return (
			<div>
				<h3 className="mb-4 text-center">
					<Trans>Previous Receipts Status</Trans>
				</h3>
				<div>
					<TableReport searchTextboxEnable={false} keyField="id" data={tableList} columns={columns} />
				</div>
			</div>
		);
	} else {
		return "";
	}
};

export default IsLoadingHOC(PreviousReceiptList, "Loading....");
