import React, { useEffect, useState } from 'react';
import Card from './Card';
import { connect, useSelector } from 'react-redux';
import { getContestAction } from '../redux/actions';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import IsLoadingHOC from '../components/IsLoadingHOC';
import { Redirect } from 'react-router-dom';

const Contests = props => {
  const user = useSelector(({ user }) => user);
  const [data, setData] = useState([]);
  const [viewMore, setViewMore] = useState(true);
  const [apiCallStatus, setApiCallStatus] = useState(false);
  const [page, setPage] = useState(0);
  const [pageRedirect, setPageRedirect] = useState(false);
  const pagesize = props.pagesize ? props.pagesize : 4;
  useEffect(() => {
    if (props.isHome) {
      setData(props.contestData);
      return;
    }
    getNextData(0);
    // eslint-disable-next-line
  }, []);
  const getNextData = page => {
    props.setLoading(true);
    props
      .getContestAction(page + 1, pagesize)
      .then(response => {
        setApiCallStatus(true);
        let activityList = response.data;
        setData([...data, ...activityList]);
        setPage(page + 1);
        setViewMore(response.viewMore);
        props.setLoading(false);
      })
      .catch(ex => {
        console.log('Error In API Call', ex);
        props.setLoading(false);
        setApiCallStatus(true);
      });
  };
  const activityCLick = async (activityData, redirectUrl) => {
    try {
      setPageRedirect(redirectUrl);
    } catch (err) {
      console.log('Error in Activity status check', err);
      props.setLoading(false);
    }
  };

  if (pageRedirect) {
    return <Redirect push to={pageRedirect} />;
  } else if (data.length === 0 && apiCallStatus) {
    return (
      <div className="page-section">
        <Row className="justify-content-center">
          <Col sm="8" className="text-center">
            <h2 className="text-center">Oops!</h2>
            <p className="mb-4 text-center">
              No contest available right now.
              <br />
              Please check back later!.
            </p>
          </Col>
        </Row>
      </div>
    );
  } else {
    return props.isHome ? (
      <>
        {data.map((item, index) => {
          return (
            <Col key={index}>
              <Card>
                <div onClick={e => activityCLick(item, `/contest/${item.id ? item.id : item['_id']}`)} className={(user.pointBalance >= item.pointRequired ? ' ' : 'activity-block-disable ') + 'activity-block'}>
                  <div className="activity-img-holder d-flex align-items-center">
                    <img src={item.cardHeaderImage} alt={item.name} />
                  </div>
                  <div className="activity-info">
                       {item.pointRequired > 0 ? (
                          <div className="points">
                             {' '}
                               <Trans>Points</Trans>: {item.pointRequired}{' '}
                                  </div>
                                      ) : ( <div className="points no-points-required">No Points Required</div>
                                        ) }
                                    <h5>{item.name}</h5>
                            </div>
                  <div className="row justify-content-center">
                    <Button className="cart-button btn btn-primary col-10" disabled={user.pointBalance < item.pointRequired ? true : false} activity-type={item.actionType} id={`spin-enter-btn-${item.id ? item.id : item['_id']}`}>
                      {user.pointBalance < item.pointRequired ? <Trans>Unlock With More Points</Trans> : <Trans>Enter Now</Trans>}
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
          );
        })}
      </>
    ) : (
      <Row xs={1} md={2} lg={4} className="card_holder">
        {data.map((item, index) => {
          return (
            <Col key={index}>
              <Card>
                <div onClick={e => activityCLick(item, `/contest/${item.id ? item.id : item['_id']}`)} className={(user.pointBalance >= item.pointRequired ? ' ' : 'activity-block-disable ') + 'activity-block'}>
                  <div className="activity-img-holder d-flex align-items-center">
                    <img src={item.cardHeaderImage} alt={item.name} />
                  </div>
                <div className="activity-info">
                                    {item.pointRequired > 0 ? (
                                         <div className="points">
                                            {' '}
                                             <Trans>Points</Trans>: {item.pointRequired}{' '}
                                           </div>
                                    ) : ( <div className="points no-points-required">No Points Required</div>
                                              ) }
                                    <h5>{item.name}</h5>
                                  </div>
                  <div className="row justify-content-center">
                    <Button className="cart-button btn btn-primary col-10" disabled={user.pointBalance < item.pointRequired ? true : false} activity-type={item.actionType} id={`spin-enter-btn-${item.id ? item.id : item['_id']}`}>
                      {user.pointBalance < item.pointRequired ? <Trans>Unlock With More Points</Trans> : <Trans>Enter Now</Trans>}
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
    );
  }
};

export default connect(null, { getContestAction })(IsLoadingHOC(Contests, 'Loading....'));
