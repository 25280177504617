import React, { useState, useEffect } from 'react';
import { useParams, Redirect, Link } from 'react-router-dom';
import { rewardsListAction, getLockupAction, redeemRewardOrderAction, balanceGetAction, rewardsOrderAddressUpdateAction, rewardOrderCompleteAction } from '../../redux/actions';
import { Container, Col, Row, Button, Card, Modal, Form, CloseButton } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import IsLoadingHOC from '../IsLoadingHOC';
import { useSelector, useDispatch } from 'react-redux';
import BraintreeDropin from '../PaymentGateways/BraintreeDropin';
import ViewOrder from './ViewOrder';
import GooglePlaces from '../GooglePlaces';
import Required from './../Required';

const RewardsPrev = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const [popupShow, setPopupShow] = useState(false);
  const [pageRedirect, setPageRedirect] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [showPaymentGeteways, setShowPaymentGeteways] = useState(false);
  const [orderData, setOrderData] = useState(false);
  const [orderDisplay, setOrderDisplay] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [data, setData] = useState({});
  const [place, setPlace] = useState({});
  const [newAddress, setNewAddress] = useState({ address: '', country: '', state: '', suite: '', city: '', zip: '' });
  const [rewardQty, setRewardQty] = useState(1);
  const [sizeList, setSizeList] = useState([]);
  const [selectedRewardId, setSelectedRewardId] = useState('');
  const query = new URLSearchParams(window.location.search);
  const groupCode = query.get('code') ? query.get('code') : '';
  const [allRewardlist, setAllRewardlist] = useState([]);
  let [countryList, setCountryList] = useState([]);
  let [stateList, setStateList] = useState([]);
  const [showDigitalRewards, setShowDigitalRewards] = useState(false);
  useEffect(() => {
    if (user && user.userInfo) {
      if ((user.userInfo?.additionalInfo?.rewardTag || '') !== '20PER-Digital') {
        if (data) {
          if (data.vendor === 'NC') {
            toast.error(`${t('Error')}: ${t('Requested entity not found')}!`);
            setPageRedirect(true);
          }
        }
      }
    }
  }, [user.userInfo, data]);
  let { id } = useParams();
  if (!id) {
    toast.error(`${t('Error')}: ${t('Requested entity not found')}!`);
    setPageRedirect(true);
  }
  useEffect(() => {
    props.setLoading(true);
    dispatch(balanceGetAction());
    rewardsListAction(id, groupCode)
      .then(response => {
        if (typeof response.data === 'object') {
          let rdata = Array.isArray(response.data) ? response.data : [response.data];
          rdata = rdata.filter(v => v.balance > 0);
          if (rdata.length > 0) {
            setData(rdata[0]);
            if (Array.isArray(response.data)) {
              let rewdAdditionalInfo = [];
              for (let i in rdata) {
                if (rdata[i].additionalInfo) {
                  if (rdata[i].additionalInfo.size) {
                    rewdAdditionalInfo.push({ ...rdata[i].additionalInfo, id: rdata[i].id, itemId: rdata[i].itemId, balance: rdata[i].balance ? rdata[i].balance : 1 });
                  }
                } //else if (!rdata[i].additionalInfo && rdata[i].groupingCode) {
                //   rewdAdditionalInfo.push({ sizeSeq: rewdAdditionalInfo.length, size: rdata[i].itemName, id: rdata[i].id, itemId: rdata[i].itemId, balance: rdata[i].balance ? rdata[i].balance : 1 });
                // }
              }
              rewdAdditionalInfo = rewdAdditionalInfo.sort((a, b) => (a.sizeSeq ? a.sizeSeq : a.size.toLowerCase() > b.sizeSeq ? b.sizeSeq : b.size.toLowerCase() ? 1 : -1));
              setSizeList(rewdAdditionalInfo);
              setAllRewardlist(rdata);
            }
          } else {
            toast.error(`${t('Sorry')}! ${t('This reward is temporarily out of stock or not found.')}!`);
            setPageRedirect(true);
          }
        } else {
          toast.error(`${t('Error')}: ${t('Requested entity not found')}!`);
          setPageRedirect(true);
        }
        props.setLoading(false);
      })
      .catch(err => {
        toast.error(`${t('Error')}: ${t('Requested entity not found')}!`);
        setPageRedirect(true);
        props.setLoading(false);
      });
    // eslint-disable-next-line
  }, [id]);
  const rewardBtnClick = e => {
    setPopupShow(false);
    props.setLoading(true);
    let revid = selectedRewardId ? selectedRewardId : id;
    redeemRewardOrderAction(revid, { qty: rewardQty })
      .then(response => {
        if (response.data) {
          dispatch(balanceGetAction());
          if ((['NC', 'VM', 'BIG'].indexOf(data.vendor) >= 0 && (data.type ? data.type.toUpperCase() : '') !== 'DIGITAL') || (data.vendor === 'PP' && data.type === 'MTH')) {
            setOrderData(response.data);
            if (response.data.shippingAddress) {
              let { shippingAddress } = response.data;
              let address = {
                address: shippingAddress.address ? shippingAddress.address : '',
                country: shippingAddress.country ? shippingAddress.country : '',
                state: shippingAddress.state ? shippingAddress.state : '',
                suite: shippingAddress.suite ? shippingAddress.suite : '',
                city: shippingAddress.city ? shippingAddress.city : '',
                zip: shippingAddress.zip ? shippingAddress.zip : '',
              };
              setNewAddress(address);
            }
            setOrderDisplay(true);
          } else {
            setShowPopup(true);
          }
        } else {
          if (response.httpCode === 406 && data.type.toUpperCase() === 'DIGITAL') {
            toast.error(`${t('Sorry')}! ${t("You've already redeemed this reward")}.`);
          } else if (response.httpCode === 403) {
            toast.error(`${t('Sorry')}! ${t('This reward is temporarily out of stock')}.`);
          } else if (response.httpCode === 412) {
            toast.error(`${t('Sorry')}! ${t('Your point balance is low')}.`);
          } else if (response.httpCode === 410) {
            toast.error(`${t('Sorry')}! ${t('Reward expired')}.`);
          } else if (response.httpCode === 417) {
            toast.error(`${t('Sorry')}! ${t('Reward redeem limit exceeded')}.`);
          } else if (response.httpCode === 423) {
            toast.error(`${t('Sorry')}! ${t('One of your previous order is already in progress. Please try after some time')}.`);
          } else {
            toast.error(`${t('Error')}: ${t('Something went wrong')}!`);
          }
        }
        props.setLoading(false);
      })
      .catch(err => {
        toast.error(`${t('Error')}: ${t('Something went wrong')}!`);
        props.setLoading(false);
      });
  };
  const completeOrder = e => {
    setPopupShow(false);
    props.setLoading(true);
    let revid = selectedRewardId ? selectedRewardId : id;
    rewardOrderCompleteAction(revid, { orderId: orderData.id })
      .then(response => {
        if (response.data) {
          dispatch(balanceGetAction());
          setShowPopup(true);
        } else {
          if (response.httpCode === 406 && data.type.toUpperCase() === 'DIGITAL') {
            toast.error(`${t('Sorry')}! ${t("You've already redeemed this reward")}.`);
          } else if (response.httpCode === 403) {
            toast.error(`${t('Sorry')}! ${t('This reward is temporarily out of stock')}.`);
          } else if (response.httpCode === 412) {
            toast.error(`${t('Sorry')}! ${t('Your point balance is low')}.`);
          } else if (response.httpCode === 410) {
            toast.error(`${t('Sorry')}! ${t('Reward expired')}.`);
          } else if (response.httpCode === 417) {
            toast.error(`${t('Sorry')}! ${t('Reward redeem limit exceeded')}.`);
          } else if (response.httpCode === 423) {
            toast.error(`${t('Sorry')}! ${t('One of your previous order is already in progress. Please try after some time.')}.`);
          } else {
            toast.error(`${t('Error')}: ${t('Something went wrong')}!`);
          }
        }
        props.setLoading(false);
      })
      .catch(err => {
        console.log('errrrrr', err);
        toast.error(`${t('Error')}: ${t('Something went wrong')}!`);
        props.setLoading(false);
      });
  };
  const orderAction = (makePayment, goBack = false) => {
    if (makePayment) {
      if (orderData.shippingAddress) {
        if (!orderData.shippingAddress.suite || !orderData.shippingAddress.address || !orderData.shippingAddress.city || !orderData.shippingAddress.country || !orderData.shippingAddress.zip) {
          toast.error(`${t('Error')}: ${t('Entered wrong address for delivery')}, ${t('Please update valid address first')}.`);
          return false;
        }
      } else {
        toast.error(`${t('Error')}: ${t('Entered wrong address for delivery')}, ${t('Please update valid address first')}.`);
        return false;
      }
      setShowPaymentGeteways(true);
    } else if (goBack) {
      setOrderDisplay(false);
    }
  };
  const saveAddress = e => {
    // if (newAddress.suite.length < 1) {
    //   toast.error(`${t('Error')}: ${t('Invalid')} ${t('lang_key_add_suite')}!`);
    //   return false;
    // } else
    let addCheck = '';
    if (newAddress.address) {
      addCheck = newAddress.address.toLowerCase();
      addCheck = addCheck.replaceAll('.', '');
      addCheck = addCheck.replaceAll('  ', ' ');
    }
    if (newAddress.address.length < 1) {
      toast.error(`${t('Error')}: ${t('Invalid')} ${t('lang_key_add_address')}!`);
      return false;
    } else if (newAddress.city.length < 1) {
      toast.error(`${t('Error')}: ${t('Invalid')} ${t('City')}!`);
      return false;
    } else if (newAddress.state.length < 1) {
      toast.error(`${t('Error')}: ${t('Invalid')} ${t('lang_key_add_state')}!`);
      return false;
    } else if (newAddress.country.length < 1) {
      toast.error(`${t('Error')}: ${t('Invalid')} ${t('lang_key_add_country')}!`);
      return false;
    } else if (newAddress.zip.length < 3) {
      toast.error(`${t('Error')}: ${t('Invalid')} ${t('lang_key_add_zip')}!`);
      return false;
    } else if (addCheck.indexOf('po box') >= 0 && data.vendor === 'BIG') {
      toast.error(`${t('Error')}: Physical rewards cannot be delivered to P.O. boxes.`);
      return false;
    }
    props.setLoading(true);
    let requestData = { address: newAddress.address, suite: newAddress.suite, city: newAddress.city, state: newAddress.state, country: newAddress.country, zip: newAddress.zip };
    rewardsOrderAddressUpdateAction(orderData.id, requestData)
      .then(response => {
        if (response.httpCode === 200 && typeof response.data === 'object') {
          if (response.data.shippingAddress) {
            let tempOrderData = JSON.parse(JSON.stringify(orderData));
            let { shippingAddress } = response.data;
            tempOrderData.shippingAddress = shippingAddress;
            setOrderData(tempOrderData);
          }
          setShowModel(false);
          toast.success(`${t('Shipping')} ${t('lang_key_add_address')}: ${t('updated successfully')}.`);
        } else {
          toast.error(`${t('Error')}: ${t('Something went wrong')}!, ${t('Please try again')}.`);
        }
        props.setLoading(false);
      })
      .catch(err => {
        toast.error(`${t('Error')}: ${t('Something went wrong')}!, ${t('Please try again')}.`);
        props.setLoading(false);
      });
  };
  const handleChange = e => {
    let tempAddress = JSON.parse(JSON.stringify(newAddress));
    let name = e.target.name;
    if (name === 'zip') {
      if (isNaN(e.target.value)) {
        //return false;
      }
    }
    tempAddress[name] = e.target.value;
    setNewAddress(tempAddress);
  };
  useEffect(() => {
    if (place.hasOwnProperty('address')) {
      let curStateData = Object.assign({}, newAddress);
      curStateData.address = place.address;
      curStateData.country = place.country;
      curStateData.state = place.state;
      curStateData.city = place.city;
      curStateData.suite = place.street;
      curStateData.zip = place.zip;
      setNewAddress(curStateData);
    }
    // eslint-disable-next-line
  }, [place]);
  const googleSelectedAddressHandler = place => {
    setPlace(place);
  };
  const sizeChangeHandler = value => {
    setSelectedRewardId(value);
    if (value !== '') {
      let ind = allRewardlist.findIndex(v => v.id === value);
      setData(allRewardlist[ind]);
    } else {
      setData(allRewardlist[0]);
    }
  };
  const rewardBtnClickHandler = () => {
    if (orderData.id && (data.vendor === 'BIG' || (data.vendor === 'PP' && data.type === 'MTH'))) {
      let addCheck = '';
      if (newAddress.address) {
        addCheck = newAddress.address.toLowerCase();
        addCheck = addCheck.replaceAll('.', '');
        addCheck = addCheck.replaceAll('  ', ' ');
      }
      if (addCheck.indexOf('po box') >= 0 && data.vendor === 'BIG') {
        toast.error(`${t('Error')}: Physical rewards cannot be delivered to P.O. boxes.`);
        return false;
      }
      setPopupShow(true);
      return true;
    }
    if (sizeList.length > 0) {
      if (!selectedRewardId) {
        toast.error(`Please select a Size.`);
      } else {
        if (data.vendor === 'BIG') {
          rewardBtnClick();
        } else {
          setPopupShow(true);
        }
      }
    } else {
      if (data.vendor === 'BIG') {
        rewardBtnClick();
      } else if (data.vendor === 'PP' && data.type === 'MTH') {
        rewardBtnClick();
      } else {
        setPopupShow(true);
      }
    }
  };
  const loadLookupDataList = () => {
    let requestData = { lookups: ['STATE_LIST', 'COUNTRY_LIST'] };
    props.setLoading(true);
    getLockupAction(requestData)
      .then(response => {
        if (response.httpCode === 200 && response.data) {
          if (response.data.content) {
            let stListInd = response.data.content.findIndex(v => v.name === 'STATE_LIST');
            if (stListInd >= 0) {
              setStateList(response.data.content[stListInd].data);
            }
            let cnListInd = response.data.content.findIndex(v => v.name === 'COUNTRY_LIST');
            if (cnListInd >= 0) {
              setCountryList(response.data.content[cnListInd].data);
            }
          }
        }
        props.setLoading(false);
      })
      .catch(e => {
        props.setLoading(false);
      });
  };
  useEffect(() => {
    loadLookupDataList();
  }, []);
  if (pageRedirect) {
    return <Redirect to="/rewards" />;
  } else if (showPaymentGeteways) {
    return (
      <Container className="rewards-list-container">
        <BraintreeDropin data={orderData} />
      </Container>
    );
  } else {
    return (
      <Container className="rewards-list-container">
        <div className="post-login">
          <Row xs={1} md={2} lg={2} className="pt-5">
            <Col>
              <Card>
                <Card.Img variant="top" src={data.image} />
              </Card>
            </Col>
            <Col>
              {orderData && orderDisplay ? (
                <ViewOrder data={orderData} rewardsDetails={data} saveAddress={saveAddress} setShowModel={setShowModel} showModel={showModel} handleChange={handleChange} newAddress={newAddress} stateList={stateList} countryList={countryList}>
                  <Row>
                    <Col md={{ span: 12 }} sm={{ span: 12 }} className="text-center">
                      <Button id={`redeem-now-btn`} className="" onClick={e => (data.vendor === 'BIG' || (data.vendor === 'PP' && data.type === 'MTH') ? rewardBtnClickHandler() : orderAction(true, false))}>
                        <Trans>Checkout</Trans>
                      </Button>
                    </Col>
                  </Row>
                </ViewOrder>
              ) : (
                <>
                  <h3 className="text-left mt-3">
                    {data.vendor === 'NC' ? (data.redeemPrice ? `$${parseInt(data.redeemPrice)} ` : '') : ''}
                    {data['itemName'] ? data.itemName : ''} {sizeList.length > 0 ? ' - ' : ''} {data?.additionalInfo?.size}
                  </h3>
                  <p className="description">{data['itemDescription'] ? data.itemDescription : ''}</p>
                  <p>
                    <strong>
                      <Trans>{data['redeemPoints'] ? `Redeem Now With ${data.redeemPoints} Points` : null}</Trans>
                    </strong>
                  </p>
                  {sizeList.length > 0 && (
                    <div className="text-center text-sm-left form rewards-prev-div2 row">
                      <Form.Group className="col-12 col-sm-5">
                        <Form.Label className="col-form-label text-left w-100">
                          Size <Required />
                        </Form.Label>
                        <Form.Control className="text-left" as="select" name="size" value={selectedRewardId} onChange={e => sizeChangeHandler(e.target.value)}>
                          <option value="">Select Size</option>
                          {sizeList.map((v, i) => {
                            return (
                              <option key={`size-${i}`} value={v.id} disabled={v.balance < rewardQty ? true : false}>
                                {`${v.size} `}
                                {v.balance < rewardQty ? '(Sold Out)' : ''}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </div>
                  )}
                  {sizeList.length == 0 && allRewardlist.length > 0 && data.vendor === 'NC' && (
                    <div className="text-center text-sm-left form rewards-prev-div2 row">
                      <Form.Group className="col-12 col-sm-8">
                        <Form.Label className="col-form-label text-left w-100">
                          Denomination <Required />
                        </Form.Label>
                        <Form.Control className="text-left" as="select" name="size" value={selectedRewardId} onChange={e => sizeChangeHandler(e.target.value)}>
                          {allRewardlist.map((v, i) => {
                            return (
                              <option key={`revd-${i}`} value={v.id} disabled={v.balance < rewardQty ? true : false}>
                                {v.redeemPrice ? `$${parseInt(v.redeemPrice)}` : v.itemName}
                                {v.balance < rewardQty ? '(Sold Out)' : ''}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </div>
                  )}
                  {data.id && data.vendor !== 'NC' ? (
                    <div className="text-center text-sm-left rewards-prev-div2 row">
                      <Form.Group className="col-12 col-sm-5">
                        <Form.Label className="col-form-label text-left w-100">
                          Quantity <Required />
                        </Form.Label>
                        <Form.Control as="select" name="rewardQty" value={rewardQty} onChange={e => setRewardQty(parseInt(e.target.value))}>
                          <option value={1}>1</option>
                          {(data.vendor === 'BIG' || data.vendor === 'PP' || data.vendor === 'DP') && (!data.tags || data.tags.indexOf('cool_7-13_7-15') < 0) ? (
                            <>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                              <option value={5}>5</option>
                            </>
                          ) : (
                            ''
                          )}
                        </Form.Control>
                      </Form.Group>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="text-center text-sm-left py-4">
                    {Object.keys(data).length > 0 ? (
                      <Button disabled={user.pointBalance < data.redeemPoints * rewardQty || (data.balance && data.balance < rewardQty) ? true : false} rewards-item={data.itemId} id={`redeem-btn-${data.id}`} className="btn btn-primary" onClick={() => rewardBtnClickHandler()}>
                        {user.pointBalance >= data.redeemPoints * rewardQty ? (data.balance && data.balance < rewardQty ? 'Sold Out' : data.vendor === 'NC' ? `Redeem Now` : 'Confirm Purchase') : t('Unlock With More Points')}
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              )}
            </Col>
          </Row>
        </div>

        <Modal show={popupShow} centered>
          <Modal.Header className="border-0" style={{ padding: '0rem 0.5rem' }}>
            <CloseButton
              className="float-right"
              onClick={() => {
                toast.error(`You must confirm your address before completing the order.`);
                setPopupShow(false);
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className="justify-content-center text-center">
                {(data.vendor === 'BIG' || (data.vendor === 'PP' && data.type === 'MTH')) && (
                  <>
                    <p>Please verify your address to ensure your reward is delivered to the correct location.</p>
                    <Container>
                      <p>{`${newAddress.suite !== '' ? newAddress.suite + ', ' : ''}${newAddress?.address + ','} ${newAddress?.city + ','} ${newAddress?.state + ','} ${newAddress?.country + ','} ${newAddress?.zip}`}</p>
                    </Container>
                  </>
                )}
                <p className="text-center">
                  <Trans>Are you sure</Trans>?
                </p>
                <button className="btn btn-primary mr-3" onClick={e => (data.vendor === 'BIG' || (data.vendor === 'PP' && data.type === 'MTH') ? completeOrder() : rewardBtnClick())}>
                  Yes
                </button>
                <button className="btn btn-light ml-3" onClick={() => setPopupShow(false)}>
                  No
                </button>
              </div>
            </Container>
          </Modal.Body>
        </Modal>
        <Modal show={showPopup} onHide={e => setShowPopup(false)}>
          {/* <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header> */}
          <Modal.Body className="show-grid">
            <Container className="reward-congratulation">
              <Row>
                <Col xs={12} md={12} className="text-center mt-4">
                  {/* <img alt="Congrates" src="/public/webstatic/images/vip-modal.png" width="200" /> */}
                  <span className="material-icons">emoji_events</span>
                </Col>
                <Col xs={12} md={12} className="text-center">
                  <h3>
                    <Trans>You've redeemed a reward</Trans>!
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} className="text-center mt-0">
                  <Trans>Please check your email for details</Trans>.
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} className="text-center my-4">
                  <Link id="href-goback" to="/rewards" className="green-text-link" onClick={e => setShowPopup(false)}>
                    <Trans>GO BACK</Trans>
                  </Link>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
};
export default IsLoadingHOC(RewardsPrev, 'Loading....');
