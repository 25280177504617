import React from 'react';
const Required = () => {
  return (
    <span>
      <span className="asterisk" aria-hidden="true"></span>
    </span>
  );
};

export default Required;
