import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import IsLoadingHOC from '../IsLoadingHOC';
import Required from './../Required';
const ViewOrder = props => {
  const { t } = useTranslation();
  const [orderData, setOrderData] = useState(false);
  const [address, setAddress] = useState('');

  useEffect(() => {
    setOrderData(props.data);
    let add = '';
    if (props.newAddress) {
      add = props.newAddress.suite;
      add = `${add}${add != '' ? ', ' : ''}${props.newAddress?.address}`;
      add = `${add}${add != '' ? ', ' : ''}${props.newAddress?.city}`;
      add = `${add}${add != '' ? ', ' : ''}${props.newAddress?.state}`;
      add = `${add}${add != '' ? ', ' : ''}${props.newAddress?.country}`;
      add = `${add}${add != '' ? ' ' : ''}${props.newAddress?.zip}`;
    }
    setAddress(add);
  }, [props]);
  return (
    <>
      <div className="table">
        <div className="row t-row">
          <div className="col-sm-4">
            <label htmlFor="OrderNo" className="col-form-label">
              Order No:
            </label>
          </div>
          <div className="col-sm-8">
            <p>{orderData ? (orderData.orderNo !== '' && orderData.orderNo ? orderData.orderNo : orderData.id) : ''}</p>
          </div>
        </div>
        <div className="row t-row">
          <div className="col-sm-4">
            <label htmlFor="OrderDate" className="col-form-label">
              Order Date:
            </label>
          </div>
          <div className="col-sm-8">
            <p>{orderData ? new Date(orderData.orderDate).toLocaleDateString() : ''}</p>
          </div>
        </div>
        <div className="row t-row">
          <div className="col-sm-4">
            <label htmlFor="Rewards" className="col-form-label">
              Rewards:
            </label>
          </div>
          <div className="col-sm-8">
            <p>{orderData ? orderData.items[0].itemName : ''}</p>
          </div>
        </div>
        <div className="row t-row">
          <div className="col-sm-4">
            <label htmlFor="Quantity" className="col-form-label">
              Quantity:
            </label>
          </div>
          <div className="col-sm-8">
            <p>{orderData ? orderData.items[0].quantity : ''}</p>
          </div>
        </div>
        <div className="row t-row">
          <div className="col-sm-4">
            <label htmlFor="Description" className="col-form-label">
              Description:
            </label>
          </div>
          <div className="col-sm-8">
            <p>{orderData ? orderData.items[0].itemDescription : ''}</p>
          </div>
        </div>
        <div className="row t-row">
          <div className="col-sm-4">
            <label htmlFor="AddressShip" className="col-form-label">
              <Trans>Shipping</Trans> <Trans>lang_key_add_address</Trans>:
            </label>
            {props.rewardsDetails.vendor === 'BIG' && <p className="bg-light p-1 pre-info mt-1">Physical rewards cannot be delivered to P.O. boxes.</p>}
          </div>
          <div className="col-sm-8">
            <p>{address}</p>
            <div className="mb-3">
              <button id={`update-address-btn`} className="btn btn-light btn-update-address" onClick={e => props.setShowModel(true)}>
                <span class="material-icons">edit</span>{' '}
                <span>
                  <Trans>Change</Trans> {/* <Trans>Shipping</Trans>  */}
                  <Trans>lang_key_add_address</Trans>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="row mt-4 ">
          <div className="col-sm-12">{props.children}</div>
        </div>
      </div>
      <Modal size="lg" show={props.showModel} animation={false}>
        <Modal.Header>
          <Modal.Title>
            <Trans>Change</Trans> <Trans>Shipping</Trans> <Trans>lang_key_add_address</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="form-group row theme-signup-address-finder">
          <label htmlFor="addressFinder" className="col-sm-2 col-form-label">
            <Trans>lang_key_select_address</Trans>
          </label>
          <div className="col-sm-10">
            <GooglePlaces getPlaceHandler={googleSelectedAddressHandler} placeholder={t('lang_key_select_address')} />
          </div>
        </div> */}

          <div className="form-group row theme-signup-suite">
            <label htmlFor="suite" className="col-sm-12 col-form-label">
              <Trans>Apt/Unit Number</Trans>
            </label>
            <div className="col-sm-12">
              <input type="text" maxLength="10" data-test="register-state" value={props.newAddress.suite} name="suite" onChange={props.handleChange} className="form-control" id="suite" placeholder={t('lang_key_add_suite')} />
            </div>
          </div>
          <div className="form-group row theme-signup-address">
            <label htmlFor="address" className="col-sm-12 col-form-label">
              <Trans>lang_key_add_address</Trans> <Required />
            </label>
            <div className="col-sm-12">
              <input type="text" maxLength="200" value={props.newAddress.address} data-test="register-address" name="address" onChange={props.handleChange} className="form-control" id="address" placeholder={t('lang_key_add_address')} />
            </div>
          </div>
          <div className="form-group row theme-signup-city">
            <label htmlFor="city" className="col-sm-12 col-form-label">
              <Trans>City</Trans> <Required />
            </label>
            <div className="col-sm-12">
              <input type="text" maxLength="100" data-test="signup-city" value={props.newAddress.city} name="city" onChange={props.handleChange} className="form-control" id="city" placeholder={t('City')} />
            </div>
          </div>
          <div className="form-group row theme-signup-state">
            <label htmlFor="state" className="col-sm-12 col-form-label">
              {t('lang_key_add_state')} <Required />
            </label>
            <div className="col-sm-12">
              <select value={props.newAddress.state} data-test="register-state" name="state" onChange={props.handleChange} className="form-control" id="state" placeholder={t('lang_key_add_state')}>
                <option value="">
                  {t('Select')} {t('State')}
                </option>
                {props.stateList
                  .filter(v => ['AE', 'DC', 'FM', 'GU', 'PR', 'VI'].indexOf(v.id) < 0)
                  .map(v => (
                    <option value={v.id}>{v.name}</option>
                  ))}
                <hr />
                {props.stateList
                  .filter(v => ['AE', 'DC', 'FM', 'GU', 'PR', 'VI'].indexOf(v.id) >= 0)
                  .map(v => (
                    <option value={v.id}>{v.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-group row theme-signup-country">
            <label htmlFor="country" className="col-sm-12 col-form-label">
              <Trans>lang_key_add_country</Trans> <Required />
            </label>
            <div className="col-sm-12">
              <select value={props.newAddress.country} data-test="register-country" name="country" onChange={props.handleChange} className="form-control" id="country" placeholder={t('lang_key_add_country')}>
                <option value="">
                  {t('Select')} {t('Country')}
                </option>
                {props.countryList.map(v => (
                  <option value={v.id}>{v.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group row theme-signup-zip">
            <label htmlFor="zip" className="col-sm-12 col-form-label">
              <Trans>Zip</Trans> <Required />
            </label>
            <div className="col-sm-12">
              <input type="text" maxLength="15" data-test="signup-zip" value={props.newAddress.zip} name="zip" onChange={props.handleChange} className="form-control" id="zip" placeholder={t('Zip')} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={e => props.setShowModel(false)}>
            <Trans>Close</Trans>
          </Button>
          <Button variant="primary" onClick={props.saveAddress}>
            <Trans>Save</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default IsLoadingHOC(ViewOrder, 'Loading....');
