import React, { useState,useEffect } from "react";
import { Row, Col, Accordion, Card, Button, Nav } from "react-bootstrap";
import { Trans } from "react-i18next";
import { useParams } from "react-router-dom";
import {faqCategory,faqData} from '../data/indexNew'

const FrequentlyAskedQuestionItems = () => {
    const { category_slug } = useParams();
	const [data, setData] = useState(faqData.filter(item => item.category_slug === category_slug)||[]);
	const [category, setCategory] = useState(faqCategory||[]);
    
	const [faqStatus, setFaqStatus] = useState(false);
    useEffect(() => {
      if(category_slug){
        let categoryData = faqCategory.filter(item => item.slug === category_slug)
        if(categoryData && categoryData.length>0){
            setCategory(categoryData[0]);
        }
      }
    }, [category_slug])
    
	const toggleHandler = (i) => {
		let tempData = JSON.parse(JSON.stringify(data));
		tempData[i].clicked = tempData[i].clicked ? false : true;
		setData(tempData);
	};
	const bulkActionHandler = () => {
		let status = !faqStatus;
		setFaqStatus(status);
		let tempData = data.map((v) => ({ ...v, clicked: status }));
		setData(tempData);
	};
	return (
		<div>
			<div className="page faq-page">
				<div className="page-section">
					<div className="container">
						<div className="">
							<Row className="justify-content-md-center section-content faq" id="faq-content">
								<Col md={12}>
									<h1 className="text-center pb-3"><Trans>Frequently Asked Questions</Trans></h1>
									{/* TOP BANNER */}
									{/* <img className="img-fluid- px-sm-5 pb-4" src={category?.image} alt="..." /> */}
								</Col>
							</Row>
						</div>
					</div>
					<div className="w-100">
						<div className="faq-wrapper">
							<div className="faq-top-banner">
								<div className="">   
									<img className="w-100 pb-4" src={category?.desktop} alt="..." />
								</div>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="faq">
							<Row>
								<Col md={12} >
									<Row className="my-4" style={{
										borderBottom: '2px solid #2d2a2a'
									}}>
										<Col>
											<h3 className=""><Trans>{category?.name}</Trans></h3>
										</Col>
										<Col className="text-right">
											<Button onClick={bulkActionHandler} variant="link" className="text-right">
												{!faqStatus ? "EXPAND ALL" : "CLOSE ALL"}
											</Button>
										</Col>
									</Row>
								</Col>
								<Col md={12}>
									<Accordion>
										{data.map((v, i) => {
											return (
												<Card style={{ marginBottom: "0px" }}>
													<Card.Header>
														<Accordion.Toggle activeKey={v.clicked} as={Nav} variant="link" onClick={(e) => toggleHandler(i)} eventKey={v.id}  data-toggle="collapse" aria-expanded="true" aria-controls="collapse">
															<Row style={{ cursor: "pointer" }}>
																<div className="col-11"><Trans>{v.name}</Trans></div>
																<div className="col-1 text-right pl-0 faq_open-close-icon">
																	{v.clicked ? "−" : "+"}
																</div>
															</Row>
														</Accordion.Toggle>
													</Card.Header>
													{v.clicked ? (
														<Accordion>
															<Card.Body dangerouslySetInnerHTML={{ __html: v.content }} />
														</Accordion>
													) : (
														<Accordion.Collapse>
															<Card.Body dangerouslySetInnerHTML={{ __html: v.content }} />
														</Accordion.Collapse>
													)}
												</Card>
											);
										})}
									</Accordion>
								</Col>
								<Col md={12} className="text-center mt-4">
									<a href="/faq" className="btn btn-link">
										<Trans>Back To FAQ Page</Trans>
									</a>
								</Col>
							</Row>
						</div>
					</div>
				</div>
			</div>
			<div className="w-100">
				<div className="faq-wrapper">
					<div className="faq-bot-banner">
						<div className="container">
							<div className="row">
								<div className="col-sm-5 d-flex align-items-center">
									<div className="">
										<h2 className="text-left">Still have questions?<br />
										We're here to help.</h2>
										<a href="https://www.nutrenaworld.com/plaid-perks-inquiry" className="btn btn-primary"  target="_blank">Contact Us</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FrequentlyAskedQuestionItems;
