import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { Container, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { portalPointAction, activityStatusAction, getUserDetails } from '../../redux/actions';
import IsLoadingHOC from '../../components/IsLoadingHOC';

const ReferFriend = props => {
  const user = useSelector(state => state.user);
  const [referalURL, setReferalURL] = useState('');
  useEffect(() => {
    if (user.id) {
      props.setLoading(true);
      props
        .getUserDetails(user.id)
        .then(response => {
          props.setLoading(false);
          if (response.data) {
            let { refCode } = response.data;
            let url = `${window.location.protocol}//${window.location.host}/register?refCode=${refCode}`;
            setReferalURL(url);
          }
        })
        .catch(error => {
          props.setLoading(false);
          console.log('error', error);
        });
    }
  }, []);

  const handleCopy = txt => {
    navigator.clipboard.writeText(txt);
    toast.success('Copied!');
  };
  return (
    <>
      <Container className="page-section">
        <Row className="justify-content-center">
          <Col xs sm="8" className="text-center">
            <h2 className="text-center">Refer a Friend</h2>
            <p className="mb-4 text-center">Earn 150 points for every friend you refer who joins Plaid Perks and uploads a qualifying receipt for purchase of Nutrena products. Limit two per calendar month. </p>
            <h3>Share This Custom Link Anywhere</h3>
            <div className="my-3 text-center">
              <a className="refer-custom-link-text d-inline">{referalURL}</a>
              <div className="mt-4">
                <button className="btn btn-primary" onClick={() => handleCopy(referalURL)}>
                  <span className="material-icons mr-2">content_copy</span> Copy
                </button>
              </div>
            </div>
            <p className="mb-4 text-center">Share your custom link in an email, instant message, or anywhere you want.</p>
            <p className="mb-4 text-center">Points will be awarded to your account once a qualifying receipt upload has been approved in your friend’s account.</p>
          </Col>
        </Row>
      </Container>
    </>
  );
};
//export default EarnPoints;
export default connect(null, { portalPointAction, getUserDetails })(IsLoadingHOC(ReferFriend, 'Loading....'));
