import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Container, Row, Col } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import IsLoadingHOC from './IsLoadingHOC';
import { toast } from 'react-toastify';
const FileDropZone = props => {
  const [selectedFile, setSelectedFile] = useState([]);
  const maxUploadSize = 5;
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (acceptedFiles.length > 0 && fileRejections.length === 0) {
      let promiceList = [];
      let pdfInd = -1,
        tfilesize = 0;
      for (let i in acceptedFiles) {
        let file = acceptedFiles[i];
        if (file.type === 'application/pdf') {
          pdfInd = i;
        } else {
          tfilesize += file.size;
          promiceList.push(fileToImageURL(file));
        }
      }
      if (pdfInd >= 0) {
        setSelectedFile([acceptedFiles[pdfInd]]);
        props.parentCallBack([acceptedFiles[pdfInd]]);
      } else {
        if (tfilesize >= maxUploadSize * 1000 * 1000) {
          toast.error(`Error: Size of the receipt must be less than ${maxUploadSize} MB.`);
        } else {
          if (promiceList.length > 0) {
            Promise.all(promiceList)
              .then(values => {
                setSelectedFile(values);
                if (values.length === 1) {
                  props.parentCallBack([acceptedFiles[0]]);
                } else {
                  props.parentCallBack(values);
                }
              })
              .catch(err => {
                console.log('error in convertion of image to image link.');
              });
          }
        }
      }
    }
    if (fileRejections.length > 0) {
      if (fileRejections.length > 5) {
        toast.error(`Error: You can only select a maximum of 5 files.`);
      } else {
        let maxFileErrCnt = 0;
        fileRejections.forEach(file => {
          file.errors.forEach(err => {
            if (err.code === 'file-too-large' && maxFileErrCnt < 1) {
              maxFileErrCnt++;
              toast.error(`Error: Size of the receipt must be less than ${maxUploadSize} MB.`);
            }
          });
        });
      }
    }
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    if (props.selectedFile.length === 0) {
      setSelectedFile([]);
    }
  }, [props.selectedFile]);
  let dropZonProps = { disabled: !props.enableUploading, onDrop, multiple: false, maxSize: maxUploadSize * 1000 * 1000, accept: 'image/jpeg,image/png,image/jpg,application/pdf' };
  if (props.multiReceiptUpload) {
    dropZonProps['multiple'] = true;
    dropZonProps['maxFiles'] = 5;
  }
  const { getRootProps, getInputProps } = useDropzone(dropZonProps);
  const fileToImageURL = file => {
    return new Promise(async (resolve, reject) => {
      try {
        const image = new Image(file);
        image.fileType = file.type;
        image.onload = () => {
          resolve(image);
        };
        image.onerror = () => {
          reject(new Error('Failed to convert File to Image.'));
        };
        image.src = URL.createObjectURL(file);
      } catch (err) {
        reject(err);
      }
    });
  };
  return (
    <Row className="justify-content-center">
      <Col md={10} {...getRootProps()} className="text-center dropzone-div ">
        <label htmlFor="fileupload" className="col-sm-12 col-form-label d-none" aria-hidden="true">
          File Upload
        </label>
        <input {...getInputProps()} accept="" id="fileupload" />
        {selectedFile.length > 0 ? (
          selectedFile.map((v, i) => {
            return (
              <div className="placeholder text-center" key={`key-${i}`}>
                {v.type === 'application/pdf' ? v.name : <img src={v.src} alt="receipt" />}
              </div>
            );
          })
        ) : (
          <p className="text-center">
            {!props.enableUploading ? (
              <>
                <Trans>Sorry</Trans>! <Trans>You have reached the program limit for rewards</Trans>.
              </>
            ) : (
              <>
                <Trans>Click here to upload receipt</Trans>.
                <br />
                <Trans>Multiple images of the same receipt can be uploaded</Trans>.
                <br />
                <Trans>Please do not upload different receipts together</Trans>.
              </>
            )}
          </p>
        )}
      </Col>
    </Row>
  );
};
export default IsLoadingHOC(FileDropZone, 'Loading....');
