import React, { useEffect, useState } from 'react';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { connect } from 'react-redux';
import { earnPointsPreview } from '../../redux/actions';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import WatchVideo from './WatchVideo';
import SurveyMonkeyQuiz from './SurveyMonkeyQuiz';
import ReferFriend from './ReferFriend';
import ReadEarn from './ReadEarn';
import { Trans } from 'react-i18next';
import ShareEarn from './ShareEarn';
import QuizQuestions from './QuizQuestions';

const EarnPoints = ({ setLoading, earnPointsPreview }) => {
  const [data, setData] = useState({});
  const [apiCallStatus, setApiCallStatus] = useState(false);
  const [actionType, setActionType] = useState(null);
  let { id } = useParams();
  useEffect(() => {
    setLoading(true);
    earnPointsPreview(id)
      .then(responseData => {
        if (responseData['data']) {
          let activityData = responseData.data;
          if (activityData.isTierEnabled && activityData.tierPoints) {
            activityData.points = activityData.tierPoints;
          }
          setData(activityData);
          setActionType(responseData['data']['actionType']);
          setLoading(false);
        } else {
          setData({});
          setLoading(false);
          setApiCallStatus(true);
        }
      })
      .catch(err => {
        setData({});
        setLoading(false);
        setApiCallStatus(true);
        console.log('Response Error:', JSON.stringify(err));
      });
    // eslint-disable-next-line
  }, [id]);
  if (apiCallStatus && Object.keys(data).length === 0) {
    return (
      <div className="">
        <div className="survey-message-div">
          <Container>
            <h1 className="h1message">
              <Trans>Error</Trans> 404! <Trans>Activity not found</Trans>
            </h1>
          </Container>
        </div>
      </div>
    );
  } else if (actionType === 'view') {
    return (
      <div className="">
        <Container>
          <WatchVideo activityData={data} />
        </Container>
      </div>
    );
  } else if (actionType === 'survey') {
    return (
      <div className="">
        <Container>{data.channel === 'quiz' ? <QuizQuestions activityData={data} /> : <SurveyMonkeyQuiz activityData={data} />}</Container>
      </div>
    );
  } else if (actionType === 'refer') {
    return (
      <div className="">
        <Container>
          <ReferFriend activityData={data} />
        </Container>
      </div>
    );
  } else if (actionType === 'read') {
    return (
      <div className="">
        <Container>
          <ReadEarn activityData={data} />
        </Container>
      </div>
    );
  } else if (actionType === 'share') {
    return (
      <div className="">
        <Container>
          <ShareEarn activityData={data} />
        </Container>
      </div>
    );
  } else if (actionType === 'click' && data.channel === 'other' && (data.points === 0 || data.points === null)) {
    return (
      <div className="portal-point-list-container container mt-5">
        <Container fluid>
          <Row xs={1} md={2} lg={2} className="mt-5">
            <Col>
              <Card>
                <Card.Img variant="top" src={data.cardHeaderImage} />
              </Card>
            </Col>
            <Col>
              <h4 className="title my-4">
                <b>{data['title'] ? data.title : ''}</b>
              </h4>
              <p className="description">{data['description'] ? data.description : ''}</p>
              <p align="center">
                {(() => {
                  if (data['title'].toLowerCase().indexOf('count 50% more points') >= 0) {
                    return (
                      <a title="Start Earning" target="_blank" href="https://www.nutrenaworld.com/dealer-locator" className="btn btn-primary text-uppercase">
                        <Trans>Start Earning</Trans>
                      </a>
                    )
                  } else if (data['title'].toLowerCase().indexOf('memorial day points bonus') >= 0) {
                    return (
                      <a title="Find a store" target="_blank" href="https://www.nutrenaworld.com/dealer-locator" className="btn btn-primary text-uppercase">
                        <Trans>Find a store</Trans>
                      </a>
                    )
                  } else {
                    return (
                      <Trans>Earn Now</Trans>
                    )
                  }
                })()}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else if (actionType !== '' && Object.keys(data).length > 0) {
    return (
      <div className="">
        <div className="survey-message-div">
          <Container>
            <h1 className="h1message">
              <Trans>Wrong Activity</Trans>
            </h1>
          </Container>
        </div>
      </div>
    );
  } else {
    return (
      <div className="">
        <div className="survey-message-div">
          <Container>
            <h1 className="h1message text-center">
              <Trans>Loading</Trans>......
            </h1>
          </Container>
        </div>
      </div>
    );
  }
};
export default connect(null, { earnPointsPreview })(IsLoadingHOC(EarnPoints, 'Loading....'));
