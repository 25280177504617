import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { defaultOptions } from '../config/index';
import { useSelector } from 'react-redux';
const GooglePlaces = props => {
  const campaignConfig = useSelector(({ app }) => app.config);
  const [countryRest, setCountryRest] = React.useState(['US']);
  React.useEffect(() => {
    if (campaignConfig.countryCode) {
      if (Array.isArray(campaignConfig.countryCode)) {
        setCountryRest(campaignConfig.countryCode);
      } else {
        setCountryRest([campaignConfig.countryCode]);
      }
    }
  }, [campaignConfig.countryCode]);

  const selectedPlaceHandler = place => {
    if (props.getPlaceHandler) {
      let placeDetails = { address: '', country: '', city: '', state: '', zip: '', street: '', latitude: 0, longitude: 0 };
      if (place.geometry) {
        if (place.geometry.location) {
          placeDetails.latitude = place.geometry.location.lat ? place.geometry.location.lat() : 0;
          placeDetails.longitude = place.geometry.location.lng ? place.geometry.location.lng() : 0;
        }
      }
      if (place.address_components) {
        let cuntryInd = place.address_components.findIndex(v => {
          return v.types.indexOf('country') >= 0 ? true : false;
        });
        placeDetails.country = cuntryInd >= 0 ? place.address_components[cuntryInd].long_name : '';

        let stateInd = place.address_components.findIndex(v => {
          return v.types.indexOf('administrative_area_level_1') >= 0 ? true : false;
        });
        placeDetails.state = stateInd >= 0 ? place.address_components[stateInd].long_name : '';

        let cityInd = place.address_components.findIndex(v => {
          return v.types.indexOf('locality') >= 0 ? true : false;
        });
        placeDetails.city = cityInd >= 0 ? place.address_components[cityInd].long_name : '';

        let pincodeInd = place.address_components.findIndex(v => {
          return v.types.indexOf('postal_code') >= 0 ? true : false;
        });
        placeDetails.zip = pincodeInd >= 0 ? place.address_components[pincodeInd].long_name : '';

        let streetInd = place.address_components.findIndex(v => {
          return v.types.indexOf('street_number') >= 0 ? true : false;
        });
        // placeDetails.street = streetInd >= 0 ? place.address_components[streetInd].long_name : "";
        // placeDetails.street =  ""

        let routeInd = place.address_components.findIndex(v => {
          return v.types.indexOf('route') >= 0 ? true : false;
        });
        if (streetInd >= 0 && routeInd >= 0) {
          placeDetails.address = place.address_components[streetInd].long_name + ' ' + place.address_components[routeInd].long_name;
        } else if (routeInd >= 0) {
          placeDetails.address = place.address_components[routeInd].long_name;
        } else if (streetInd >= 0) {
          placeDetails.address = place.address_components[streetInd].long_name;
        } else {
          placeDetails.address = '';
        }
      }
      props.getPlaceHandler(placeDetails);
    }
  };
  const { ref } = usePlacesWidget({
    apiKey: defaultOptions.googleMapAPIKey,
    onPlaceSelected: place => selectedPlaceHandler(place),
    options: {
      types: defaultOptions.googleMapLoactionTypes,
      componentRestrictions: { country: countryRest },
    },
  });
  const handleChange = e => {
    if (!e.target.value) {
      let placeDetails = { address: '', country: '', city: '', state: '', zip: '', street: '', latitude: 0, longitude: 0 };
      props.getPlaceHandler(placeDetails);
    }
  };

  return <input type="text" id="addressFinder" onChange={handleChange} placeholder={props.placeholder} defaultValue={props.value ? props.value : ''} ref={ref} className="form-control" />;
};
export default GooglePlaces;
