import React, { useEffect, useState } from 'react';
import Card from '../Card';
import { rewardsListAction, balanceGetAction } from '../../redux/actions';
import { Button, Container, Col, Row } from 'react-bootstrap';
import IsLoadingHOC from '../IsLoadingHOC';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

const RewardsList = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const pageLimit = props.pagesize ? props.pagesize : 4;
  const [managePageNo, setManagePageNo] = useState({ print_at_home: 0, mail_at_home: 0, feed_it_forward: 0, apparels: 0, headwear: 0, plaid_gear: 0, digitalRewards: 0 });
  const [manageRewardData, setManageRewardData] = useState({ print_at_home: [], mail_at_home: [], feed_it_forward: [], apparels: [], headwear: [], plaid_gear: [], digitalRewards: [] });
  const [pageRedirect, setPageRedirect] = useState(false);
  const [showDigitalRewards, setShowDigitalRewards] = useState(false);
  useEffect(() => {
    dispatch(balanceGetAction());
    getRewardsList();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (user && user.userInfo) {
      if ((user.userInfo?.additionalInfo?.rewardTag || '') === '20PER-Digital') {
        setShowDigitalRewards(true);
      } else {
        setShowDigitalRewards(false);
      }
    } else {
      setShowDigitalRewards(false);
    }
  }, [user.userInfo]);
  const getRewardsList = () => {
    props.setLoading(true);
    rewardsListAction()
      .then(response => {
        if (response.data) {
          let rewData = { print_at_home: [], mail_at_home: [], feed_it_forward: [], apparels: [], headwear: [], plaid_gear: [], digitalRewards: [] };
          let groupCodes = [];
          let groupCodesNC = [];
          for (let i in response.data) {
            if (response.data[i].balance > 0) {
              if (response.data[i].vendor === 'PP' && response.data[i].type === 'PAH') {
                rewData.print_at_home.push(response.data[i]);
              } else if (response.data[i].vendor === 'PP' && response.data[i].type !== 'PAH') {
                rewData.mail_at_home.push(response.data[i]);
              } else if (response.data[i].vendor === 'BIG') {
                let grpName = response.data[i].groupingCode ? response.data[i].groupingCode : response.data[i].id;
                if (groupCodes.indexOf(grpName) < 0) {
                  groupCodes.push(grpName);
                  if (response.data[i].tags) {
                    if (response.data[i].tags.indexOf('APPAREL') >= 0) {
                      rewData.apparels.push(response.data[i]);
                    } else if (response.data[i].tags.indexOf('HEADWEAR') >= 0) {
                      rewData.headwear.push(response.data[i]);
                    } else {
                      rewData.plaid_gear.push(response.data[i]);
                    }
                  }
                }
              } else if (response.data[i].vendor === 'FF' || response.data[i].vendor === 'DP') {
                rewData.feed_it_forward.push(response.data[i]);
              } else if (response.data[i].vendor === 'NC') {
                let grpNameNc = response.data[i].groupingCode ? response.data[i].groupingCode : response.data[i].id;
                if (groupCodesNC.indexOf(grpNameNc) < 0) {
                  groupCodesNC.push(grpNameNc);
                  rewData.digitalRewards.push(response.data[i]);
                }

                //rewData.digitalRewards.push(response.data[i]);
              }
            }
          }
          window.scrollTo(0, 0);
          setManageRewardData(rewData);
        }
        props.setLoading(false);
      })
      .catch(ex => {
        console.log('Error In API Call', ex);
        props.setLoading(false);
      });
  };
  const cardClick = async (redirectUrl, redeemPoints) => {
    // if (user.pointBalance < redeemPoints) {
    //   return false;
    // }
    setPageRedirect(redirectUrl);
  };

  const viewMoreClickHandler = type => {
    setManagePageNo({ ...managePageNo, [type]: managePageNo[type] + 3 });
  };
  if (pageRedirect) {
    return <Redirect push to={pageRedirect} />;
  } else {
    return (
      <Container fluid>
        <div className="post-login">
          <Container className="rewards-list-container my-5">
            {/* PRINT OR MAIL TO HOME COUPONS START*/}
            {props.showCouponBlock && manageRewardData.print_at_home.length > 0 && (
              <>
                <Container>
                  <h2 className="mb-3 text-left mt-4">PRINT OR MAIL TO HOME COUPONS</h2>
                </Container>
                <div>
                  <Row xs={1} md={2} lg={4} className="card_holder">
                    {manageRewardData.print_at_home.slice(0, (managePageNo.print_at_home + 1) * pageLimit).map((item, index) => {
                      return (
                        <Col key={index}>
                          <Card>
                            <div onClick={e => cardClick(`/reward-preview/${item.id}`, item.redeemPoints)} className="activity-block">
                              <div className="activity-img-holder d-flex align-items-center">
                                <img src={item.image} alt={item.itemName} style={{ width: '100%' }} />
                              </div>
                              <div className="activity-info">
                                <div className="points">
                                  <Trans>Points</Trans>: {item.redeemPoints}
                                </div>
                                <h5>{item.itemName}</h5>
                              </div>
                              <div className="row justify-content-center">
                                <Button id="btn-id" className="cart-button btn btn-primary col-10" disabled={user.pointBalance < item.redeemPoints || (item.balance && item.balance <= 0) ? true : false}>
                                  {item.balance && item.balance <= 0 ? 'Sold Out' : `${item.redeemPoints} points`}
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                  {(managePageNo.print_at_home + 1) * pageLimit < manageRewardData.print_at_home.length && (
                    <div className="text-center col my-4">
                      {' '}
                      <Button variant="secondary" id="view-more-print-at-home-btn" className="viewMore" onClick={() => viewMoreClickHandler('print_at_home')}>
                        <Trans>View More</Trans>
                      </Button>{' '}
                    </div>
                  )}
                </div>
              </>
            )}
            {/* PRINT OR MAIL TO HOME COUPONS END*/}
            {/* MAIL AT HOME COUPONS START*/}
            {props.showCouponBlock && manageRewardData.mail_at_home.length > 0 && (
              <>
                <Container>
                  <h2 className="mb-3 text-left mt-4">MAIL TO HOME COUPONS</h2>
                </Container>
                <div>
                  <Row xs={1} md={2} lg={4} className="card_holder">
                    {manageRewardData.mail_at_home.slice(0, (managePageNo.mail_at_home + 1) * pageLimit).map((item, index) => {
                      return (
                        <Col key={index}>
                          <Card>
                            <div onClick={e => cardClick(`/reward-preview/${item.id}`, item.redeemPoints)} className="activity-block">
                              <div className="activity-img-holder d-flex align-items-center">
                                <img src={item.image} alt={item.itemName} style={{ width: '100%' }} />
                              </div>
                              <div className="activity-info">
                                <div className="points">
                                  <Trans>Points</Trans>: {item.redeemPoints}
                                </div>
                                <h5>{item.itemName}</h5>
                              </div>
                              <div className="row justify-content-center">
                                <Button id="btn-id" className="cart-button btn btn-primary col-10" disabled={user.pointBalance < item.redeemPoints || (item.balance && item.balance <= 0) ? true : false}>
                                  {item.balance && item.balance <= 0 ? 'Sold Out' : `${item.redeemPoints} points`}
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                  {!showDigitalRewards && <div id="donetion_section"></div>}
                  {(managePageNo.mail_at_home + 1) * pageLimit < manageRewardData.mail_at_home.length && (
                    <div className="text-center col my-4">
                      {' '}
                      <Button variant="secondary" id="view-more-mail-at-home-btn" className="viewMore" onClick={() => viewMoreClickHandler('mail_at_home')}>
                        <Trans>View More</Trans>
                      </Button>{' '}
                    </div>
                  )}
                </div>
              </>
            )}
            {/* MAIL AT HOME COUPONS START*/}
            {/*DIGITAL REWRTDS START*/}
            {showDigitalRewards && manageRewardData.digitalRewards.length > 0 && (
              <>
                <Container>
                  <h2 className="mb-3 text-left mt-4">DIGITAL REWARDS</h2>
                </Container>
                <div>
                  <Row xs={1} md={2} lg={4} className="card_holder">
                    {manageRewardData.digitalRewards.slice(0, (managePageNo.digitalRewards + 1) * pageLimit).map((item, index) => {
                      return (
                        <Col key={index}>
                          <Card>
                            <div onClick={e => cardClick(`/reward-preview/${item.id}${item.groupingCode ? `?code=${item.groupingCode}` : ''}`, item.redeemPoints)} className="activity-block">
                              <div className="activity-img-holder d-flex align-items-center">
                                <img src={item.image} alt={item.itemName} style={{ width: '100%' }} />
                              </div>
                              <div className="activity-info">
                                {/* <div className="points">
                                  <Trans>Points</Trans>: {item.redeemPoints}
                                </div> */}
                                <h5>{item.itemName}</h5>
                              </div>
                              <div className="row justify-content-center">
                                <Button id="btn-id" className="cart-button btn btn-primary col-10">
                                  Redeem Now
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                  <div id="donetion_section"></div>
                  {(managePageNo.digitalRewards + 1) * pageLimit < manageRewardData.digitalRewards.length && (
                    <div className="text-center col my-4">
                      {' '}
                      <Button variant="secondary" id="view-more-mail-at-home-btn" className="viewMore" onClick={() => viewMoreClickHandler('digitalRewards')}>
                        <Trans>View More</Trans>
                      </Button>{' '}
                    </div>
                  )}
                </div>
              </>
            )}
            {/* DIGITAL REWRTDS END*/}

            {/* FEED IT FORWARD START */}
            {props.showFeedItForwardBlock && manageRewardData.feed_it_forward.length > 0 && (
              <div id="donetion_section2">
                <Container>
                  <h2 className="mb-3 text-left mt-4">FEED IT FORWARD</h2>
                </Container>
                <div>
                  <Row xs={1} md={2} lg={4} className="card_holder">
                    {manageRewardData.feed_it_forward.slice(0, (managePageNo.feed_it_forward + 1) * pageLimit).map((item, index) => {
                      return (
                        <Col key={index}>
                          <Card>
                            <div onClick={e => cardClick(`/reward-preview/${item.id}`, item.redeemPoints)} className="activity-block">
                              <div className="activity-img-holder d-flex align-items-center">
                                <img src={item.image} alt={item.itemName} style={{ width: '100%' }} />
                              </div>
                              <div className="activity-info">
                                <div className="points">
                                  <Trans>Points</Trans>: {item.redeemPoints}
                                </div>
                                <h5>{item.itemName}</h5>
                              </div>
                              <div className="row justify-content-center">
                                <Button id="btn-id" className="cart-button btn btn-primary col-10" disabled={user.pointBalance < item.redeemPoints || (item.balance && item.balance <= 0) ? true : false}>
                                  {item.balance && item.balance <= 0 ? 'Sold Out' : `${item.redeemPoints} points`}
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                  {(managePageNo.feed_it_forward + 1) * pageLimit < manageRewardData.feed_it_forward.length && (
                    <div className="text-center col my-4">
                      {' '}
                      <Button variant="secondary" id="view-more-feed-it-forward-btn" className="viewMore" onClick={() => viewMoreClickHandler('feed_it_forward')}>
                        <Trans>View More</Trans>
                      </Button>{' '}
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* FEED IT FORWARD END */}
            {/* APPARELS START */}
            {props.showApparelsBlock && manageRewardData.apparels.length > 0 && (
              <div id="apparels-section">
                <Container>
                  <h2 className="mb-3 text-left mt-4">Apparel</h2>
                </Container>
                <div>
                  <Row xs={1} md={2} lg={4} className="card_holder">
                    {manageRewardData.apparels.slice(0, (managePageNo.apparels + 1) * pageLimit).map((item, index) => {
                      return (
                        <Col key={index}>
                          <Card>
                            <div onClick={e => cardClick(`/reward-preview/${item.id}${item.groupingCode ? `?code=${item.groupingCode}` : ''}`, item.redeemPoints)} className="activity-block">
                              <div className="activity-img-holder d-flex align-items-center">
                                <img src={item.image} alt={item.itemName} style={{ width: '100%' }} />
                              </div>
                              <div className="activity-info">
                                <div className="points">
                                  <Trans>Points</Trans>: {item.redeemPoints}
                                </div>
                                <h5>{item.itemName}</h5>
                              </div>
                              <div className="row justify-content-center">
                                <Button id="btn-id" className="cart-button btn btn-primary col-10" disabled={user.pointBalance < item.redeemPoints || (item.balance && item.balance <= 0) ? true : false}>
                                  {item.balance && item.balance <= 0 ? 'Sold Out' : `${item.redeemPoints} points`}
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                  {(managePageNo.apparels + 1) * pageLimit < manageRewardData.apparels.length && (
                    <div className="text-center col my-4">
                      {' '}
                      <Button variant="secondary" id="view-more-apparels-btn" className="viewMore" onClick={() => viewMoreClickHandler('apparels')}>
                        <Trans>View More</Trans>
                      </Button>{' '}
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* APPARELS END */}
            {/* Headwear START */}
            {props.showHeadWearBlock && manageRewardData.headwear.length > 0 && (
              <>
                <Container>
                  <h2 className="mb-3 text-left mt-4">Headwear</h2>
                </Container>
                <div>
                  <Row xs={1} md={2} lg={4} className="card_holder">
                    {manageRewardData.headwear.slice(0, (managePageNo.headwear + 1) * pageLimit).map((item, index) => {
                      return (
                        <Col key={index}>
                          <Card>
                            <div onClick={e => cardClick(`/reward-preview/${item.id}${item.groupingCode ? `?code=${item.groupingCode}` : ''}`, item.redeemPoints)} className="activity-block">
                              <div className="activity-img-holder d-flex align-items-center">
                                <img src={item.image} alt={item.itemName} style={{ width: '100%' }} />
                              </div>
                              <div className="activity-info">
                                <div className="points">
                                  <Trans>Points</Trans>: {item.redeemPoints}
                                </div>
                                <h5>{item.itemName}</h5>
                              </div>
                              <div className="row justify-content-center">
                                <Button id="btn-id" className="cart-button btn btn-primary col-10" disabled={user.pointBalance < item.redeemPoints || (item.balance && item.balance <= 0) ? true : false}>
                                  {item.balance && item.balance <= 0 ? 'Sold Out' : `${item.redeemPoints} points`}
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                  {(managePageNo.headwear + 1) * pageLimit < manageRewardData.headwear.length && (
                    <div className="text-center col my-4">
                      {' '}
                      <Button variant="secondary" id="view-more-headwear-btn" className="viewMore" onClick={() => viewMoreClickHandler('headwear')}>
                        <Trans>View More</Trans>
                      </Button>{' '}
                    </div>
                  )}
                </div>
              </>
            )}
            {/* Headwear END */}
            {/* Plaid Gear START */}
            {props.showPladGearBlock && manageRewardData.plaid_gear.length > 0 && (
              <>
                <Container>
                  <h2 className="mb-3 text-left mt-4">Plaid Gear</h2>
                </Container>
                <div>
                  <Row xs={1} md={2} lg={4} className="card_holder">
                    {manageRewardData.plaid_gear.slice(0, (managePageNo.plaid_gear + 1) * pageLimit).map((item, index) => {
                      return (
                        <Col key={index}>
                          <Card>
                            <div onClick={e => cardClick(`/reward-preview/${item.id}${item.groupingCode ? `?code=${item.groupingCode}` : ''}`, item.redeemPoints)} className="activity-block">
                              <div className="activity-img-holder d-flex align-items-center">
                                <img src={item.image} alt={item.itemName} style={{ width: '100%' }} />
                              </div>
                              <div className="activity-info">
                                <div className="points">
                                  <Trans>Points</Trans>: {item.redeemPoints}
                                </div>
                                <h5>{item.itemName}</h5>
                              </div>
                              <div className="row justify-content-center">
                                <Button id="btn-id" className="cart-button btn btn-primary col-10" disabled={user.pointBalance < item.redeemPoints || (item.balance && item.balance <= 0) ? true : false}>
                                  {item.balance && item.balance <= 0 ? 'Sold Out' : `${item.redeemPoints} points`}
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                  {(managePageNo.plaid_gear + 1) * pageLimit < manageRewardData.plaid_gear.length && (
                    <div className="text-center col my-4">
                      {' '}
                      <Button variant="secondary" id="view-more-plaid-gear-btn" className="viewMore" onClick={() => viewMoreClickHandler('plaid_gear')}>
                        <Trans>View More</Trans>
                      </Button>{' '}
                    </div>
                  )}
                </div>
              </>
            )}
            {/* Plaid Gear END */}
            <div id="donetion_section"></div>
          </Container>
        </div>
      </Container>
    );
  }
};

export default IsLoadingHOC(RewardsList, 'Loading....');
