import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import IsLoadingHOC from '../IsLoadingHOC';
import { connect, useSelector } from 'react-redux';
import { contestAction } from '../../redux/actions';
import Winwheel from 'winwheel';
import { toast } from 'react-toastify';
const WheelEarn = props => {
  const contestData = props.data;
  const user = useSelector(({ user }) => user);
  const { t } = useTranslation();
  const [segments, setSegments] = useState([]);
  const [wheelSpinning, setWheelSpinning] = useState(false);
  const [parentId, setParentId] = useState('');
  const [lowPoint, setLowPoint] = useState(false);
  const [showSpinResultPopup, setShowSpinResultPopup] = useState(false);
  const [wheelResult, setWheelResult] = useState(null);
  const isSmallScreen = window.screen.width > 800 ? false : true;
  useEffect(() => {
    if (contestData.data) {
      let data = contestData.data.map(v => {
        return { text: v.text, segmentId: v.segmentId, fillStyle: v.bgColor, textFillStyle: v.textColor ? v.textColor : '#000', segmentType: v.segmentType };
      });
      setSegments(data);
    }
    if (user.pointBalance < contestData.pointRequired) {
      setLowPoint(true);
    }
    // eslint-disable-next-line
  }, [props]);
  const setLoading = props.setLoading;
  let { id } = useParams();
  window.alertPrize = async indicatedSegment => {
    let data = {
      parentId: parentId,
    };
    if (props.urlType === 'public') {
      data['token'] = id;
      data['contestHistoryId'] = parentId;
    } else {
      data['contestId'] = id;
    }
    props
      .contestAction(data, 'put', props.urlType)
      .then(responseData => {
        setWheelSpinning(false);
        setWheelResult(JSON.parse(JSON.stringify(indicatedSegment)));
        setParentId('');
        if (responseData.httpCode >= 200 && responseData.httpCode <= 299) {
          setShowSpinResultPopup(true);
          if (responseData.httpCode === 206) {
            setParentId(responseData.data.parentId);
          }
        } else if (responseData.httpCode === 406) {
          toast.error(`${t(`Oops`)}! ${t(`Low On Points`)}.`);
          setLowPoint(true);
        } else if (responseData.httpCode === 403) {
          toast.error(`${t(`Oops`)}! ${t(`You have reached the maximum limit`)}.`);
          setLowPoint(true);
        } else {
          toast.error(`${t(`Something went wrong`)}.`);
        }
        setLoading(false);
      })
      .catch(err => {
        setWheelSpinning(false);
        if (typeof err === 'object') {
          if (err.httpCode === 406) {
            toast.error(`${t(`Oops`)}! ${t(`Low On Points`)}.`);
            setLowPoint(true);
          } else if (err.httpCode === 403) {
            toast.error(`${t(`Oops`)}! ${t(`You have reached the maximum limit`)}.`);
            setLowPoint(true);
          } else {
            toast.error(`${t(`Something went wrong`)}.`);
          }
        } else {
          toast.error(`${t(`Something went wrong`)}.`);
        }
      });
  };

  let theWheel = new Winwheel({
    numSegments: segments.length,
    outerRadius: isSmallScreen ? 160 : 260,
    textFontSize: isSmallScreen ? 15 : 25,
    textFontWeight: 'bold',
    textOrientation: 'curved',
    textFontFamily: 'Courier',
    textAlignment: 'outer',
    innerRadius: isSmallScreen ? 50 : 85,
    segments: segments,
    rotationAngle: 0,
    textMargin: isSmallScreen ? 10 : 20,
    // Specify the animation to use.
    animation: {
      type: 'spinToStop',
      duration: 5, // Duration in seconds.
      spins: 8, // Number of complete spins.
      easing: 'Power4.easeOut',
      yoyo: false,
      repeat: 0,
      callbackFinished: 'window.alertPrize(winwheelToDrawDuringAnimation.getIndicatedSegment())',
    },
  });
  window.theWheel = theWheel;
  const startWheel = e => {
    setWheelSpinning(true);
    let data = {
      parentId: parentId,
    };
    if (props.urlType === 'public') {
      data['token'] = id;
      data['contestId'] = contestData.id;
    } else {
      data['contestId'] = id;
    }
    props
      .contestAction(data, 'post', props.urlType)
      .then(responseData => {
        if (responseData.httpCode === 406) {
          toast.error(`${t(`Oops`)}! ${t(`Low On Points`)}.`);
          setLowPoint(true);
          setWheelSpinning(false);
        } else if (responseData.httpCode === 403) {
          toast.error(`${t(`Oops`)}! ${t(`You have reached the maximum limit`)}.`);
          window.location.reload();
          setWheelSpinning(false);
        } else if (responseData.httpCode === 201) {
          setParentId(responseData.data.id);
          let segmentInd = segments.findIndex(v => v.segmentId === responseData.data.segmentId);
          if (wheelSpinning === false) {
            //define Stop angle start
            let segmentAngle = 360 / segments.length;
            let stopAt = segmentAngle * segmentInd + segmentAngle / 2;
            theWheel.animation.stopAngle = stopAt;
            //define Stop angle End
            theWheel.startAnimation();
            setWheelSpinning(true);
          }
        } else {
          toast.error(`${t(`Something went wrong`)}.`);
          setWheelSpinning(false);
        }
      })
      .catch(err => {
        setWheelSpinning(false);
        if (typeof err === 'object') {
          if (err.httpCode === 406) {
            toast.error(`${t(`Oops`)}! ${t(`Low On Points`)}.`);
            setLowPoint(true);
          } else if (err.httpCode === 403) {
            toast.error(`${t(`Oops`)}! ${t(`You have reached the maximum limit`)}.`);
            window.location.reload();
          }
        } else {
          console.log('Response Error:', JSON.stringify(err));
          setLoading(false);
          toast.error(`${t(`Something went wrong`)}.`);
        }
      });
  };
  const resetWheel = () => {
    theWheel.stopAnimation(false); // Stop the animation, false as param so does not call callback function.
    theWheel.rotationAngle = 0; // Re-set the wheel angle to 0 degrees.
    theWheel.draw();
    setWheelSpinning(false);
    setWheelResult(null);
    setShowSpinResultPopup(false);
  };
  return (
    <div className="portal-point-list-container">
      <div className="page-section">
        <div className="post-login">
          <Row className="pt-3">
            <Col xs={12} sm={7} md={7} className={lowPoint ? 'opicity06' : ''}>
              <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center', position: 'relative', width: '100%', justifyContent: 'center' }}>
                <img style={{ position: 'absolute', top: '-20px', zIndex: 10 }} alt="pointer" src="/public/activities/wheel_pointer.png" />
                <canvas data-responsivemargin="600" data-responsiveminwidth="380" data-responsivescaleheight="true" height={isSmallScreen ? 350 : 600} id="canvas" width={isSmallScreen ? 520 : 640} style={{ zIndex: 2 }}>
                  <h3 className="text-center" style={{ color: '#646464' }}>
                    {' '}
                    Sorry, your browser doesn't support canvas. Please try another browser.
                  </h3>
                </canvas>
                <img style={{ position: 'absolute', width: isSmallScreen ? 350 : 600, top: isSmallScreen ? 0 : 0, zIndex: 1 }} src="/public/activities/spin_board_bg.png" />
              </div>
            </Col>
            <Col xs={12} sm={5} md={5}>
              <Row md={12}>
                {/* <Col xs={12} md={12} className="text-center py-2 mt-5 d-block d-sm-none">
                  {contestData.allowSpin || !lowPoint ? (
                    <Button variant="primary" disabled={wheelSpinning} onClick={startWheel}>
                      {wheelSpinning ? <Spinner animation="border" variant="light" /> : <Trans>Click To Spin!</Trans>}
                    </Button>
                  ) : (
                    ''
                  )}
                </Col> */}
                {lowPoint ? (
                  <Col xs={12} md={12} className="text-left">
                    <p className="pt-5">Leap Smart Rewards Spin & Win</p>
                    <h1>Sorry, you’re all out of spins for now!</h1>
                    <p>Please come back later to try again.</p>
                    <div className="text-left pt-4- pb-3">
                      <Link to="/earnpoints" className="btn btn-primary">
                        <Trans>Earn More Points</Trans>
                      </Link>
                    </div>
                  </Col>
                ) : (
                  <Col className="pt-5 text-left">
                    <p>Leap Smart Rewards Spin & Win</p>
                    <h1>Spin and lets see what you get!</h1>
                    <p className="description mt-3">
                      Click below to start spinning! You could win one of our awesome prizes. <br />
                      Click{' '}
                      <a href="/public/assets/doc/Leap_Rewards_TC_07-28-2022-CLEAN.pdf" target="_blank">
                        here{' '}
                      </a>{' '}
                      to read the rules.
                    </p>
                  </Col>
                )}
                <Col xs={12} md={12} className="text-left py-4">
                  {contestData.allowSpin || !lowPoint ? (
                    <Button variant="primary" disabled={wheelSpinning} onClick={startWheel}>
                      {wheelSpinning ? <Spinner animation="border" variant="light" /> : <Trans>Click To Spin!</Trans>}
                    </Button>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <Modal show={showSpinResultPopup} size="lg">
        <Modal.Body>
          <Row>
            <Col xs={12} md={12} className="text-center">
              <p>{wheelResult && wheelResult.segmentType === 'sorry' ? `${t('Oops')}! ${t('Sorry')} ${t('better luck next time')}` : `${t('You won')} ${wheelResult ? wheelResult.text : ''}`}</p>
              <div className="text-center pt-4 mb-3">
                {wheelResult && wheelResult.segmentType === 'free_spin' ? (
                  <Button variant="primary" onClick={resetWheel}>
                    <Trans>Spin Now</Trans>
                  </Button>
                ) : (
                  <Link id="back-btn" className="btn btn-warning" to="/contest">
                    <Trans>GO BACK</Trans>
                  </Link>
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default React.memo(connect(null, { contestAction })(IsLoadingHOC(WheelEarn, 'Loading....')));
