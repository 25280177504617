import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { getCampaignSubsetData } from "../redux/actions";
import IsLoadingHOC from "./IsLoadingHOC";
const TierInformation = (props) => {
	const token = useSelector(({ user }) => user.accessToken);
	const [useTier, setUserTier] = useState(null);
	const [userTierIndex, setUserTierIndex] = useState(0);
	const [data, setData] = useState(null);
	const parseJwt = (token) => {
		try {
			return JSON.parse(atob(token.split(".")[1]));
		} catch (e) {
			return null;
		}
	};
	useEffect(() => {
		if (token) {
			let tokenData = parseJwt(token);
			if (tokenData) {
				setUserTier(tokenData.tier);
			} else {
				setUserTier(null);
			}
			props.setLoading(true);
			getCampaignSubsetData({ reqAttributes: { pointTierConfig: 1 } })
				.then((response) => {
					if (response.data.content) {
						setData(response.data.content[0].pointTierConfig);
					}
					props.setLoading(false);
				})
				.catch((ex) => {
					console.log("Error In API Call", ex);
					setData(null);
					props.setLoading(false);
				});
		}
		// eslint-disable-next-line
	}, [token]);
	useEffect(() => {
		if (useTier && token && data) {
			setUserTierIndex(data.findIndex((v) => parseInt(v.tierId) === parseInt(useTier)));
		}
	}, [token, useTier, data]);
	if (token) {
		return (
			<Container fluid className="border-top border-info bg-light tier-information">
				<Row className="justify-content-md-center mt-2">
					<Col md={10} sm={12} className="justify-content-center">
						<Row className="justify-content-md-center">
							<div className="col-4 col-md-3">
								<span className="p-1 float-left text-right">
									<Trans>Current level</Trans>
									<br />
									<Trans>{useTier && data ? data[userTierIndex].tierName : ""}</Trans>
								</span>
								<span className="float-left d-none d-sm-block">
									<img src="/public/webstatic/images/perk-1-icon.png" alt="Prev Level" />
								</span>
							</div>
							<div className="col-4 col-md-6 px-0 px-md-3">
								<ol className="c-stepper">
									{data
										? data.map((v, i) => {
												return (
													<li title={v.tierId} key={v.tierId} className={`c-stepper__item ${i === userTierIndex ? "c-stepper__item_active" : ""}`}>
														<p className="c-stepper__title">{v.tierName}</p>
													</li>
												);
										  })
										: ""}
								</ol>
							</div>
							{data && data.length - 1 === userTierIndex ? (
								""
							) : (
								<div className="col-4 col-md-3">
									<span className="p-1 float-right">
										<Trans>Next level</Trans>
										<br />
										<Trans>{useTier && data ? data[userTierIndex + 1].tierName : ""}</Trans>
									</span>
									<span className="float-right d-none d-sm-block">
										<img src="/public/webstatic/images/perk-2-icon.png" alt="Next Level" />
									</span>
								</div>
							)}
						</Row>
					</Col>
				</Row>
			</Container>
		);
	} else return "";
};
export default IsLoadingHOC(TierInformation, "Loading....");
